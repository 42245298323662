import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
                <div className="inner">
                    <ul className="icons">
                        <li><a href="//twitter.com/BrandMcG" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
                        <li><a href="//soundcloud.com/funkbomb-1" className="icon fa-soundcloud"><span className="label">Soundcloud</span></a></li>
                        <li><a href="//github.com/BrandonSM" className="icon fa-github"><span className="label">Github</span></a></li>
                    </ul>
                    <ul className="copyright">
                        <li>&copy; BrandonMcGinnis.com</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Footer
