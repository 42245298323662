import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/newavi.jpg'

class Header extends React.Component {

    randoStringGenerator() {

        var HeaderChoice = [
           { "title": 'web developer', "background":'slick web BG'},
           {"title": 'app developer', "background":'cool app background'},
           {"title": 'terrible golfer', "background": 'golf hole bg'},
           {"title": 'bbq slayer', "background":'something smoked bbq'},
           {"title": 'musician', "background": 'guitars bg'},
           {"title": 'business owner', "background": 'something entrepreneur'},
           {"title": 'dad', "background": 'something dad bg'},
           {"title": 'husband', "background": 'something husband/wife'},
           {"title": 'tech geek', "background": 'some code or something'},
        ]
    // Array of words
        var words = [
            'web & app developer', 
            'terrible golfer', 
            'bbq slayer', 
            'bass player', 
            'business owner', 
            'dad', 
            'husband'
        ];
    
        // Function that executes every 2000 milliseconds
        var rando = setInterval(function() {
            // Random number generator
            var randomNumber = Math.round( Math.random() * (words.length-1) );
            console.log(randomNumber);

            var HeaderRandomNumber = HeaderChoice[randomNumber].title;
            console.log("Header ID: " + HeaderRandomNumber);

            var BackgroundURL = HeaderChoice[randomNumber].background;
            console.log("Background URL: " + BackgroundURL);
            
            // Change the word in the span for a random one in the array of words
            window.document.getElementById("changing").innerHTML = words[randomNumber]}, 4000);
    }


    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="#" className="image avatar"><img src={avatar} alt="" /></a>
                    <h1>My name's <strong>Brandon McGinnis</strong></h1>
                    <h1>I'm a <strong><span id="changing">{this.randoStringGenerator() || 'dad'}</span>.</strong></h1>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
