import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

class HomeIndex extends React.Component {

    constructor() {
        super();
        this.state = {
            
        };
    }

    render() {
        const siteTitle = "Welcome! BrandonMcGinnis.com"
        const siteDescription = "Web Development, App Development, Game Development, Music, BBQ"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>Your big opportunity may be right where you are now.<br/><em>-- Napoleon Hill</em></h2>
                        </header>
                        <p>An old friend said to me when I first started learning to code <em>"You have to learn to learn, and then keep learning."</em> Since then, I've been doing just that.</p>
                        <p>You can usually find me hanging out with my amazing wife and son, or trying to learn something. If I'm not hacking on some code, I might be hacking on the <a href="#">golf course</a>.</p>
                        <p>I'm a big fan of collaboration and if you're interested in making <a href="//soundcloud.com/funkbomb-1" target="_blank" rel="noopener noreferrer">music</a>, building a <a href="//stonecreative.io" target="_blank" rel="noopener noreferrer">website or app</a>, or want to talk about <a href="//thenotoriousbbq.com" target="_blank" rel="noopener noreferrer">BBQ Smoke</a> - hit me up!</p>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default HomeIndex